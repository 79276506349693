import * as React from 'react';
import { Select } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

interface ReportingLevelProps {
  setProjectId: (val: string) => void;
  setLoaded: (val: boolean) => void;
}

const ReportingLevel = ({
  setProjectId,
  setLoaded,
}: ReportingLevelProps): JSX.Element => {
  const { projects } = useSelector((state: any) => state.projects);

  // Handlers
  const selectOption = React.useCallback(
    (e) => {
      setLoaded(false);
      setProjectId(e.target.value);
    },
    [setLoaded, setProjectId]
  );

  return (
    <Select onChange={selectOption} maxW="400px" size="md" variant="filled">
      <option value="account">Account</option>
      {projects.map((e: any) => {
        return (
          <option key={e?.projectId} value={e?.projectId}>
            {e?.name}
          </option>
        );
      })}
    </Select>
  );
};

export default ReportingLevel;
